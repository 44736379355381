<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">BioKeep - بايو كيب</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ e("active-account") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ e("message-sent-to-whatsapp") }}
            {{ user.phone }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- name -->
              <b-form-group label="كود التفعيل" label-for="login-email2">
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required"
                >
                  <b-form-input
                    id="login-email2"
                    v-model="code"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    :placeholder="e('write-here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="success"
                class="btn-lg"
                block
                @click="validationForm"
              >
                {{ e("active-it-now") }}
                <i class="fa-solid fa-arrow-left"></i>
              </b-button>
            </b-form>
          </validation-observer>

          <div class="col g text-center">
            <b-link to="/logout" class="text-danger">
              {{ e("logout") }}
            </b-link>
          </div>
          <div class="col g text-center">
            {{ e("an_company") }}
            <a href="https://brmja.com" class="text-danger" target="_blank">
              {{ e("brmja") }}
            </a>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      code: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var _g = this;
    $.get(
      "https://ipinfo.io/?callback&token=264a47086f74f4",
      function (response) {
        _g.country = response.country;
      }
    );
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    e(d) {
      return e(d);
    },
    validationForm() {
      var _g = this;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          $.post(api + "/auth/activate", {
            jwt: this.user.jwt,
            code: this.code,
          })
            .then(function (response) {
              response = JSON.parse(response);
              if (response.status == 100) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: e("done-activated"),
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                setTimeout(() => {
                  window.location = "/";
                }, 1000);
              } else {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: e(response.response),
                    icon: "TimesIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .catchd(function (e) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
